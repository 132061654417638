<template>
  <div class="wrapper">
    <!-- <div class="pay-top">
      <p>商品名称：<span>{{cartList.goodsName}}</span></p>
      <p v-if="index==0 || abcPayDiscount<=0"><span class="colorred">¥{{cartList.allPrice}}</span></p>
      <p v-if="index==1 && abcPayDiscount > 0"><span class="colorred">¥{{finalPrice}}</span></p>
    </div> -->
    <div class="pay-top">
      <p>商品金额：<span>￥{{allAmount}}</span></p>
      <p v-if="index==0">需支付：<span>￥{{allAmount}}</span></p>
      <p v-if="index==1">需支付：<span>￥{{finalPrice}}</span></p>
    </div>
    <div class="pay-type">
      <div class="type-list-titl">
        <span>请选择支付方式</span>
      </div>
      <div class="type-list" @click="selectPayType(0)">
        <img class="type-list-l" src="../../images/winxin_icon.png" />
        <span>微信支付</span>
        <span class="select-wrap"><img :class="{'type-list-r': true, 'showSelect': index===0}" src="../../images/icon_01.png" /></span>
      </div>
      <div class="type-list" @click="selectPayType(1)" style="padding-bottom: .4rem">
        <img class="type-list-l" src="../../images/bank_01.png" />
        <span>农行支付</span>
        <span class="select-wrap"><img  :class="{'type-list-r': true, 'showSelect': index===1}" src="../../images/icon_01.png" /></span>
        <span class="discount-info">享受{{abcPayDiscount}}折优惠</span>
        <span class="discount-info2">可为您节省{{discountAmount}}元</span>
      </div>
    </div>
    <a class="login-btn pay-btn" href="javascript:;" @click="onPay">立即支付</a>
  </div>
  <Toast v-if="show" :message="toastMessage"/>
</template>
<script>
import qs from 'qs'
import { onMounted, reactive, toRefs, getCurrentInstance, onUnmounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { parseUrlParam } from '../../units/tools.js'
import { post, ajaxParam, config } from '../../units/request.js'
import Toast, { useToastEffect } from '../../components/Toast.vue'
// import wx from 'weixin-js-sdk'
export default {
  name: 'PayType',
  components: { Toast },
  setup () {
    const store = useStore()
    const router = useRouter()
    const data = reactive({ isGroup: '1', index: 0, orderSn: '', orderInfo: '', abcPayDiscount: 0, code: '', appId: '', timeStamp: '', nonceStr: '', paySign: '', package: '', allAmount: 0 })
    const { cartList, originAddress } = toRefs(store.state)
    const { show, toastMessage, showToast } = useToastEffect()
    const { proxy } = getCurrentInstance()
    let inter = null
    onMounted(() => {
      data.isGroup = proxy.$route.query.isGroup || '1'
      if (data.isGroup === '0') {
        data.allAmount = proxy.$route.query.allAmount
      } else if (data.isGroup === '1') {
        data.allAmount = cartList.value.allPrice
      }
      data.orderSn = proxy.$route.query.paySn
      const href = window.location.href
      if (href.includes('?code')) {
        const code = parseUrlParam(href).code
        if (code) {
          data.code = code
          getWxInfo()
        }
      } else {
      }
      const getStatus = async () => {
        const param = {
          paySn: data.orderSn,
          snType: 1
        }
        ajaxParam(param)
        const res = await post('v1/orderInfo/getOrderPayStatus.json', qs.stringify(param))
        if (res.data.payType === '1' && res.data.payStatus === '2') {
          data.index = 0
        }
        if (res.data.payType === '5' && res.data.payStatus === '2') {
          data.index = 1
        }
        if (res.data.payStatus === '2') {
          router.push({
            name: 'PaySuccess',
            params: {
              index: data.index,
              allAmount: data.allAmount,
              isGroup: data.isGroup
            }
          })
        }
      }
      getStatus()
      inter = setInterval((res) => {
        getStatus()
      }, 5000)
      window.onpopstate = (e) => {
        router.replace({ name: 'HomeNew' })
      }
    })
    onUnmounted(() => {
      clearInterval(inter)
      const href = window.location.href
      if (href.includes('?code')) {
        const urlRight = href.split('#')[1]
        const urlLeft = href.split('?')[0]
        window.location.href = urlLeft + '#' + urlRight
      }
    })
    const getCode = () => {
      let redirectUrl = window.location.href
      redirectUrl = encodeURIComponent(redirectUrl)
      const appid = config.appId
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
    }
    const selectPayType = (index) => {
      data.index = index
    }
    const onPay = () => {
      if (data.index === 0) {
        wxPay()
      }
      if (data.index === 1) {
        bankPay()
      }
    }
    const wxPay = async () => {
      getCode()
    }
    const getWxInfo = async () => {
      const param = {
        code: data.code,
        userId: localStorage.userId,
        paySn: data.orderSn,
        snType: 1
      }
      ajaxParam(param)
      const res = await post('v1/wxJsApiPay/getOrderPayInfo', qs.stringify(param))
      data.appId = config.appId
      data.timeStamp = res.data.orderInfo.timeStamp
      data.nonceStr = res.data.orderInfo.nonceStr
      data.paySign = res.data.orderInfo.paySign
      data.package = res.data.orderInfo.package
      // 检测支付环境中的WeixinJSBridge
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
        }
      } else {
        onBridgeReady()
      }
    }
    const onBridgeReady = () => {
      const param = {
        appId: data.appId, // 公众号名称，由商户传入
        timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
        nonceStr: data.nonceStr, // 随机串
        package: data.package,
        signType: 'MD5', // 微信签名方式
        paySign: data.paySign // 微信签名
      }
      window.WeixinJSBridge.invoke('getBrandWCPayRequest', param, res => {
        // 支付成功
        if (res.err_msg === 'get_brand_wcpay_request:ok') {
          showToast('支付成功')
          data.index = 0
          setTimeout(res => {
            router.push({
              name: 'PaySuccess',
              params: {
                index: data.index,
                allAmount: data.allAmount,
                isGroup: data.isGroup
              }
            })
          }, 3000)
        }
        // 支付过程中用户取消
        if (res.err_msg === 'get_brand_wcpay_request:cancel') {
          showToast('支付取消')
        }
        // 支付失败
        if (res.err_msg === 'get_brand_wcpay_request:fail') {
          showToast('支付失败')
        }
      })
    }
    const bankPay = async () => {
      const param = {
        userId: localStorage.userId,
        paySn: data.orderSn,
        snType: 1
      }
      ajaxParam(param)
      const res = await post('v1/abcPay/getOrderPayInfo', qs.stringify(param))
      if (res.data.code === '200') {
        data.orderInfo = res.data.orderInfo
        location.href = data.orderInfo
      }
    }
    const getshopPrice = async () => {
      const param = {
        shopIds: config.shopId,
        customerUserId: localStorage.userId || ''
      }
      ajaxParam(param)
      const res = await post('v1/userShop/getShopsPrice.json', qs.stringify(param))
      if (res.data.code === '200') {
        data.abcPayDiscount = res.data.list[0].abcPayDiscount
      }
    }
    getshopPrice()
    const finalPrice = computed(() => {
      const rPrice = data.allAmount * data.abcPayDiscount / 100
      return rPrice.toFixed(2)
    })
    const discountAmount = computed(() => {
      let rPrice = data.allAmount * data.abcPayDiscount / 100
      rPrice = rPrice.toFixed(2)
      const discountAmount = data.allAmount - rPrice
      return discountAmount.toFixed(2)
    })
    const { index, abcPayDiscount, allAmount } = toRefs(data)
    return { allAmount, show, toastMessage, showToast, onPay, selectPayType, bankPay, cartList, index, originAddress, abcPayDiscount, finalPrice, discountAmount }
  }
}
</script>
<style scoped lang="scss">
.pay-top {
  background: #fff;
  padding: 0.2rem;
  font-size: 0.14rem;
  p{
    line-height: .3rem;
  }
}
.pay-type {
  background: #fff;
  margin-top: 0.2rem;
  padding: 0 0.2rem;
}
.type-list {
  padding: 0.2rem 0;
  border-bottom: 1px solid #f3f3f3;
  line-height: 0.3rem;
  position: relative;
  font-size: 0.14rem;
}
.type-list-titl {
  border-bottom: 1px solid #f3f3f3;
  color: #52da5f;
  padding: .2rem 0;
  font-size: .16rem;
}
.type-list .type-list-l {
  float: left;
  width: 0.26rem;
  height: 0.26rem;
  margin-right: 0.2rem;
}
.discount-info{
  float: right;
  height: .2rem;
  margin: .05rem .15rem 0 0;
  padding: 0 .04rem;
  border: 1px solid #ff371e;
  font-size: .12rem;
  line-height: .2rem;
  color:#ff371e;
}
.discount-info2{
  position: absolute;
  bottom: .1rem;
  right: .5rem;
  color:#ff371e;
  font-size: .12rem;
}
.select-wrap{
  float: right;
  width: 0.2rem;
  height: 0.2rem;
  margin-right: 0.15rem;
}
.type-list .type-list-r {
  width: 0.2rem;
  height: 0.2rem;
  margin-top: .05rem;
  display: none;
}
.pay-btn {
  margin-top: 1rem;
}
.login-btn {
  display: block;
  height: 0.48rem;
  margin: 0.42rem 0.4rem 0.26rem 0.4rem;
  background: #52da5f;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 145, 255, 0.32);
  border-radius: 0.25rem;
  color: #fff;
  font-size: 0.16rem;
  line-height: 0.48rem;
  text-align: center;
}
.type-list .showSelect{
  display: block;
}
.colorred{
  color:#ff371e
}

.order-intro{
  display: flex;
  margin: 0 .2rem;
  align-items: center;
}
.order-intro p{
  flex:1;
  padding:0 0 0 .1rem;
  height: .2rem;
  line-height: .2rem;
  font-size: .12rem;
  background:red;
  color:#fff
}
.order-intro span{
  display: inline-block;
  margin-left: .2rem;
  padding-left: .4rem;
  background: #eee url("../../images/icon_share_h.png") no-repeat 0.15rem center;
  background-size: 0.18rem 0.18rem;
  font-size: .15rem;
  line-height: .18rem
}
</style>
